import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

// Elements
import Image from 'components/shared/Image'

const CustomImage = styled(Image)`
	width: 255px;

	@media (max-width: 991px){
		width: 100%;
	}
`

const Name = styled.p`
	color: ${props => props.theme.color.text.blue};
	text-transform: uppercase;
`

const Team = () => {
	const { team } = useStaticQuery(graphql`
		{
			team: allWordpressWpTeam {
				edges {
					node {
						acf {
							teamlid {
								image {
									localFile {
										publicURL
									}
								}
								function
							}
						}
					}
				}
			}
		}
	`)

  return (
		<div className="row">
			{team.edges.map(({ node: { acf: { teamlid  } } }, index) => (
				<div className="col-lg-3 col-md-6 mb-4 mb-lg-5">
					<CustomImage src={teamlid.image} className="mb-3" />
					<Name className="text-center">{teamlid.function}</Name>
				</div>
			))}
		</div>
	)
}

export default Team