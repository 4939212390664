/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Elements
import TitleDefault from 'components/elements/TitleDefault'
import TitleSecondary from 'components/elements/TitleSecondary'
import ParseContent from 'components/shared/ParseContent'
import Image from 'components/shared/Image'
import Team from 'components/shared/Team'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'

const AboutImage = styled(Image)` 
  width: 100%;
`

const StallImage = styled(Image)`
  width: 100%;

  @media (max-width: 991px){
    height: 250px;
    object-fit: cover;
  }
`

const Content = styled(ParseContent)`
  & h2 {
    font-size: ${props => props.theme.font.size.l};
    line-height: ${props => props.theme.font.size.l};
  }
`

const PageTemplate = ({
  data: {
    page: { title, path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} article />

      <section>
        <div className="container py-lg-3">
          <div className="row py-5">
            <div className="col-lg-5 pt-lg-5 ">
              <TitleDefault>{acf.banner.title}</TitleDefault>
              <Content className="pr-lg-5 mb-4 mb-lg-0" content={acf.banner.description} />
            </div>
            <div className="col-lg-7">
              <AboutImage src={acf.banner.image} />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row py-4 py-lg-5">
            {acf.benefits.benefit.map(({ title, description }, index) => (
              <div className="col-lg-4 text-center mb-3 mb-lg-0" key={index}>
                <TitleSecondary className="mb-3">{title}</TitleSecondary>
                <ParseContent content={description} />
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-3">
       <StallImage src={acf.marketStall.image} />
      </section>

      {/* <section>
        <div className="container pb-5">
          <div className="row pt-5">
            <div className="col-12">
             <TitleDefault className="mb-4">{acf.team.title}</TitleDefault>
            </div>
          </div>
          <Team />
        </div>
      </section> */}
      
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      
      acf {
        banner {
          title
          description
          image {
            localFile {
              publicURL
            }
          }
        }
        
        benefits {
          benefit {
            title
            description
          }
        }

        marketStall: market_stall {
          image {
            localFile {
              publicURL
            }
          }
        }

        team {
          title
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
